import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/how-to-get-started-buyer.jpeg";
import TopBackgroundMobile from "../images/headersMobile/7-how-to-get-started-buyer-mobile.jpg";
import SignupIcon from "../images/icon/signupIcon.svg";
import EmailIcon from "../images/icon/emailIcon.svg";
import LoginIcon from "../images/icon/loginIcon.svg";
import RocketIcon from "../images/icon/rocketIcon.svg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("how-to-get-started.topTitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: false,
      image: {
        src: TopBackground,
        alt: t("how-to-get-started.imgAlt"),
        smallHeight: false
      },
    },
    topInfoMobile: {
      title: {
        text: t("how-to-get-started.topTitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: false,
      image: {
        src: TopBackgroundMobile,
        alt: t("how-to-get-started.imgAlt"),
        smallHeight: false
      },
    },
    topTitle: {
      title: {
        text: t("how-to-get-started.forBuyers"),
        colorClass: "colorDark",
        extraClass: "letterSpacing3",
      },
      textCenter: true,
      separator: true,
      topDiagonal: false,
      backgroundGrey: false,
    },
    startedProcess: [
      {
        icon: {
          src: SignupIcon,
          alt: t("how-to-get-started.signupAlt"),
        },
        title: t("how-to-get-started.signupTitle"),
        text: process.env.GATSBY_CHINA === "true" ? t("how-to-get-started.signupTextChina"): t("how-to-get-started.signupText"),
        before: true,
        href: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      },
      {
        icon: {
          src: EmailIcon,
          alt: t("how-to-get-started.emailAlt"),
        },
        title: t("how-to-get-started.confirmTitle"),
        text: t("how-to-get-started.confirmText"),
        before: true,
      },
      {
        icon: {
          src: LoginIcon,
          alt: t("how-to-get-started.loginAlt"),
        },
        title: t("how-to-get-started.loginTitle"),
        text: t("how-to-get-started.loginText"),
        before: true,
      },
      {
        icon: {
          src: RocketIcon,
          alt: t("how-to-get-started.getStartedAlt"),
        },
        title: t("how-to-get-started.startedTitle"),
        text: t("how-to-get-started.startedText"),
        href: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      },
    ],
    cta: {
      text: t("how-to-get-started.joinFree"),
      url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
      extraClass: "bigWidth",
    },
  };
};
