import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/how-to-get-started";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import TitleSeparator from "../components/titleSeparator";
import IconAnimation from "../components/iconAnimation";
import ButtonLink from "../components/_partials/_buttonLink";

const MOBILE_WIDTH = 992;
class startedPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: (typeof window !== "undefined") ? (window.innerWidth <= MOBILE_WIDTH ? true : false):'',
    };
    this.t = props.t;
    this.text = pageTexts(this.t);
  }


  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MOBILE_WIDTH ? true : false;
    this.setState({
      mobile: show,
    });
  };



  render() {
    return (
      <>
        <Layout pageName="how-to-get-started" whiteMenu={false}>
          <TextImgBackgroundFull info={this.state.mobile ? this.text.topInfoMobile : this.text.topInfo}></TextImgBackgroundFull>
          <div className="paddingSidesBig pt-30 pb-50">
            <div className="text-center pb-30">
              <TitleSeparator info={this.text.topTitle}></TitleSeparator>
            </div>
            <div className="row row-cols-lg-4 row-cols-md-4 row-cols-sm-1 row-cols-1">
              {this.text.startedProcess.map((item, i) => (
                <div className="text-center" key={i}>
                  <IconAnimation info={item}></IconAnimation>
                </div>
              ))}
            </div>
          </div>
          <div className="paddingSidesBig backgroundGrey topDiagonal pt-30">
            <div className="row justify-content-center pt-50 pb-75">
              <ButtonLink
                text={this.text.cta.text}
                url={this.text.cta.url}
                type={this.text.cta.type}
                extraClass={this.text.cta.extraClass}
              ></ButtonLink>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(startedPage);
